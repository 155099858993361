import {
  BackgroundImage,
  Container,
  Grid,
  Space,
  Text,
  Image,
  Card,
  Flex,
  Group,
} from "@mantine/core";
import React from "react";
import classes from "../../../../styles/styles.module.css";
import { services } from "../../../../constants/services";
import { useNavigate } from "react-router-dom";

function ServicesSection() {
  const navigate = useNavigate();

  return (
    <BackgroundImage src="/images/services-bg.png">
      <Container
        fluid
        px={"xl"}
        py={"md"}
        style={{
          width: "100%",
        }}
        id="our-services"
      >
        <Text
          c={"#2B71B9"}
          style={{
            textDecorationColor: "#F21A27",
            fontSize: "2.5vw",
            textUnderlineOffset: "15px",
            textDecorationLine: "underline",
            textDecorationThickness: "6px",
          }}
          fw={700}
          ta={"center"}
          className={classes["service-title"]}
        >
          Our services
        </Text>
        <Space h={"xl"} />
        <Space h={"xl"} />
        <Flex gap={32} justify={"center"} wrap={"wrap"}>
          {services?.map((service, index) => (
            <div
              style={{
                position: "relative",
                height: "18rem",
                width: "15rem",
              }}
              className={classes["service-card"]}
              key={index}
            >
              <Image
                src={service.image}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
              <Card
                radius={0}
                className={classes["service-desc-card"]}
                onClick={() => {
                  navigate(service.link);
                }}
              >
                <Group justify="center">
                  <Text size="xs" ta={"center"} fw={700} c={"#FFF"}>
                    {service.title}
                  </Text>
                  <div className={classes["service-desc-arrow"]}>
                    <Image
                      src={"/images/arrow-right-icon.png"}
                      style={{
                        width: "35px",
                      }}
                    />
                  </div>
                </Group>
              </Card>
            </div>
          ))}
        </Flex>
        <Space h={150} />
      </Container>
    </BackgroundImage>
  );
}

export default ServicesSection;
