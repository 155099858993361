import { Container, Flex, Image, Space, Stack, Text } from "@mantine/core";
import React from "react";

function AboutUsSection() {
  return (
    <Container
      fluid
      p={0}
      style={{
        width: "100%",
        minHeight: "89vh",
      }}
      id="about-us"
    >
      <Flex gap={0}>
        <Image
          src="/images/about-us-one.png"
          style={{
            height: "100%",
            width: "50%",
          }}
        />

        <Container fluid p={"xl"} bg={"#2B71B9"}>
          <Flex direction={"column"}>
            <Text
              c={"#FFF"}
              style={{
                textDecorationColor: "#F21A27",
                fontSize: "2.5vw",
                textUnderlineOffset: "15px",
                textDecorationLine: "underline",
                textDecorationThickness: "6px"
              }}
              fw={700}
            >
              About Us
            </Text>
            <Space h={"xl"} />
            <Stack gap={20}>
              <Text c={"#FFF"}>
                Gathering Investment and Bridging Resources for Opportunities (GIBRO) Enterprise Limited
              </Text>
              <Text c={"#FFF"}>
                GIBRO Enterprise Limited (GEL) is committed to transforming water resource management and fostering a sustainable future in Kenya. Leveraging advanced groundwater development equipment and a dedicated team of professionals, we deliver innovative solutions to improve water access, treatment, and conservation.
              </Text>
              <Text c={"#FFF"}>
              Water scarcity remains a pressing issue in Kenya, with millions lacking access to clean water. At GEL, we address this challenge through sustainable approaches, including groundwater development, water treatment, and storage solutions. Our state-of-the-art drilling unit has successfully developed water wells in underserved urban areas, bringing clean water to communities in critical need.
              </Text>
              <Text c={"#FFF"}>
              Collaboration drives our success. We work closely with communities, government bodies, and organizations to promote sustainable water practices that secure resources for future generations. By combining cutting-edge technology with local involvement, we ensure our projects are impactful and long-lasting.
              </Text>
              <Text c={"#FFF"}>
              At GIBRO, we are more than a water solutions provider—we are partners in building a better future. By choosing us, you invest in clean water access, community empowerment, and environmental stewardship. Together, we can create a Kenya where clean, safe water is accessible to all.
              </Text>
            </Stack>
          </Flex>
        </Container>
      </Flex>
    </Container>
  );
}

export default AboutUsSection;
