import { Box, Flex, Stack, Text, Image, Anchor } from "@mantine/core";
import React from "react";
import { useLocation } from "react-router-dom";

function NavigationBar() {
  let location = useLocation();

  return (
    <Box
      style={{
        backgroundColor: "#2B71B9",
        position: "fixed",
        zIndex: 10,
        width: "100%",
        minHeight: "7.3rem",
      }}
    >
      <Flex justify={"center"} p={"xl"}>
        <Flex
          direction={"column"}
          style={{
            position: "fixed",
          }}
          gap={16}
        >
          <Flex align={"center"} style={{ color: "#FFF" }} gap={64} px={"xl"}>
            <Flex gap={10}>
              <Image
                src={"/images/phone-icon.png"}
                w={16}
                h={16}
                style={{
                  marginTop: "2px",
                }}
              />
              <Stack gap={0}>
                <Text fw={700} size="xs">
                  Call Us
                </Text>
                <Text fw={500} size="xs">0785 887 206
                </Text>
              </Stack>
            </Flex>
            <Flex gap={10}>
              <Image
                src={"/images/email-icon.png"}
                w={20}
                h={14}
                style={{
                  marginTop: "2px",
                  borderRadius: "2px",
                }}
              />
              <Stack gap={0}>
                <Text fw={700} size="xs">
                  Email Us
                </Text>
                <Text fw={500} size="xs">
                  info@gibroenterprise.com
                </Text>
              </Stack>
            </Flex>
            <Flex gap={10}>
              <Image
                src={"/images/location-icon.png"}
                w={16}
                h={16}
                style={{
                  marginTop: "2px",
                }}
              />
              <Stack gap={0}>
                <Text fw={700} size="xs">
                  Address
                </Text>
                <Text fw={500} size="xs">
                  P.O Box 818 - 00520, Ruai , Nairobi
                </Text>
              </Stack>
            </Flex>

            <Flex align={"center"} gap={16}>
              <Anchor href="#" target="_blank">
                <Image src={"/images/facebook-icon.png"} h={22} w={22} />
              </Anchor>
              <Anchor href="#" target="_blank">
                <Image src={"/images/linkedin-icon.png"} h={22} w={22} />
              </Anchor>
              <Anchor href="#" target="_blank">
                <Image src={"/images/twitter-icon.png"} h={22} w={22} />
              </Anchor>
              <Anchor href="#" target="_blank">
                <Image src={"/images/youtube-icon.png"} h={22} w={22} />
              </Anchor>
              <Anchor href="#" target="_blank">
                <Image src={"/images/whatsapp-icon.png"} h={22} w={22} />
              </Anchor>
            </Flex>
          </Flex>

          <Flex
            align={"center"}
            style={{
              width: "100%",
              height: "4rem",
            }}
          >
            <Box
              style={{
                backgroundColor: "#FFF",
                borderRadius: "65px 0px 0px 65px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
              }}
              px={"xl"}
              py={"xs"}
            >
              <Flex
                pl={"sm"}
                align={"center"}
                gap={64}
                justify={"space-between"}
              >
                <Image src={"/images/logo-one.png"} w={60} h={45} />

                <Anchor
                  href="#home"
                  style={{
                    textDecoration: "none",
                    color:
                      location?.hash === "#home" || location?.hash === ""
                        ? "#F21A27"
                        : "#2B71B9",
                  }}
                >
                  <Text size="sm" fw={700}>
                    Home
                  </Text>
                </Anchor>
                <Anchor
                  href="#about-us"
                  style={{
                    textDecoration: "none",
                    color:
                      location?.hash === "#about-us" ? "#F21A27" : "#2B71B9",
                  }}
                >
                  <Text size="sm" fw={700}>
                    About Us
                  </Text>
                </Anchor>
                <Anchor
                  href="#our-services"
                  style={{
                    textDecoration: "none",
                    color:
                      location?.hash === "#our-services"
                        ? "#F21A27"
                        : "#2B71B9",
                  }}
                >
                  <Text size="sm" fw={700}>
                    Our Services
                  </Text>
                </Anchor>
                <Anchor
                  href="#operation-hours"
                  style={{
                    textDecoration: "none",
                    color:
                      location?.hash === "#operation-hours"
                        ? "#F21A27"
                        : "#2B71B9",
                  }}
                >
                  <Text size="sm" fw={700}>
                    Operation Hours
                  </Text>
                </Anchor>
              </Flex>
            </Box>

            <Box
              style={{
                backgroundColor: "#F21A27",
                borderRadius: "0 65px 65px 0",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "20%",
              }}
              pr={"xl"}
              pl={"xs"}
              py={"xs"}
            >
              <Anchor
                href="#contact-us"
                style={{
                  textDecoration: "none",
                }}
              >
                <Text size="sm" fw={700} style={{ color: "#FFF" }}>
                  Contact Us
                </Text>
              </Anchor>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default NavigationBar;
