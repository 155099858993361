import React from "react";
import { Container, Text, Flex, Anchor, useMantineTheme } from "@mantine/core";
import { ArrowDown } from "@phosphor-icons/react";
import classes from '../../../../styles/styles.module.css'

function MobileHomeSection() {
  const theme = useMantineTheme();

  return (
    <Container
      fluid
      p={"md"}
      style={{
        height: "100%",
      }}
      id="home"
    >
      <Flex
        direction={"column"}
        justify={"center"}
        style={{
          height: "100%",
          paddingTop: "35vh",
        }}
      >
        <Text
          style={{ color: "#FFF", lineHeight: 1.2 }}
          className={classes['home-sec-title-text']}
          fw={700}
          ta={"center"}
        >
          Water Is
        </Text>
        <Text
          style={{ color: "#FFF", lineHeight: 1.2 }}
          fw={700}
          ta={"center"}
          className={classes['home-sec-title-text']}
        >
          Our
        </Text>
        <Text
          style={{
            textDecorationColor: "#F21A27",
            textUnderlineOffset: "30px",
            textDecorationLine: "underline",
            lineHeight: 1.2,
            color: "#FFF",
          }}
          fw={700}
          ta={"center"}
          className={classes['home-sec-title-text']}
        >
          Business
        </Text>
        <Flex justify={"center"} style={{ marginTop: "auto" }}>
          <Anchor
            href="#company-statement"
            style={{
              textDecoration: "none",
            }}
          >
            <div
              style={{
                padding: "10px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F21A27",
              }}
            >
              <ArrowDown size={20} color="#FFF" />
            </div>
          </Anchor>
        </Flex>
      </Flex>
    </Container>
  );
}

export default MobileHomeSection;
