import { BackgroundImage, Flex, Group, Text } from "@mantine/core";
import { CaretRight } from "@phosphor-icons/react";
import React from "react";

function BreadCrumb({ serviceDetails }) {
  return (
    <BackgroundImage
      src="/images/services/service-bg-one.png"
      style={{
        height: "20rem",
        paddingTop: "5rem",
      }}
    >
      <BackgroundImage
        src="/images/services/service-bg-two.png"
        style={{
          height: "100%",
        }}
      >
        <Flex
          justify={"center"}
          direction={"column"}
          p={32}
          style={{
            height: "100%",
          }}
        >
          <Text c={"#FFF"} style={{ fontSize: "2vw" }} fw={700}>
            {serviceDetails?.title}
          </Text>
          <Group gap={10}>
            <Text c={"#FFF"} fw={600} style={{ fontSize: "1vw" }}>
              Home
            </Text>
            <CaretRight size={30} style={{ color: "#FFF" }} />
            <Text c={"#FFF"} fw={600} style={{ fontSize: "1vw" }}>
              Services
            </Text>
            <CaretRight size={30} style={{ color: "#FFF" }} />
            <Text c={"#FFE602"} fw={600} style={{ fontSize: "1vw" }}>
              {serviceDetails?.title}
            </Text>
          </Group>
        </Flex>
      </BackgroundImage>
    </BackgroundImage>
  );
}

export default BreadCrumb;
