import {
  Anchor,
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Image,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";
import classes from "../../../../styles/styles.module.css";
import { ArrowUp } from "@phosphor-icons/react";

function MobileCustomersSection() {
  return (
    <Container
      fluid
      px={"xl"}
      py={"md"}
      style={{
        width: "100%",
      }}
      id="customers"
    >
      <Center>
        <Box
          style={{
            width: "100%",
          }}
        >
          <Text
            c={"#2B71B9"}
            fw={700}
            ta={"center"}
            className={classes["customer-title"]}
            style={{
              lineHeight: 1.2,
            }}
          >
            Hundreds of Clients Trust Gibro
          </Text>
          <Divider
            size={6}
            mt={10}
            mx={"auto"}
            color={"#F21A27"}
            style={{
              width: "60vw",
            }}
          />
        </Box>
      </Center>
      <Space h={40} />
      <Text c={"#676767"} size="sm">
        with its unparalleled service delivery and expertise in providing access
        to clean, sustainable water sources
      </Text>
      <Space h={40} />
      <Center>
        <Image
          src={"/images/map-stats.png"}
          style={{
            width: "60vw",
          }}
        />
      </Center>
      <Space h={40} />
      <Flex align={"center"} justify={"center"} gap={32} wrap={"wrap"}>
        <Stack gap={0} align="center">
          <Image
            src={"/images/stack-icon.png"}
            style={{
              width: "10vw",
            }}
          />
          <Text c={"#2B71B9"} fw={800} style={{ fontSize: 40 }}>
            70+
          </Text>
          <Text size="sm" c={"#2B71B9"} fw={400}>
            Projects completed{" "}
          </Text>
        </Stack>

        <Stack gap={0} align="center">
          <Image
            src={"/images/user-icon.png"}
            style={{
              width: "10vw",
            }}
          />
          <Text c={"#2B71B9"} fw={800} style={{ fontSize: 40 }}>
            100+
          </Text>
          <Text size="sm" c={"#2B71B9"} fw={400}>
            Happy Customers{" "}
          </Text>
        </Stack>

        <Stack gap={0} align="center">
          <Image
            src={"/images/user-icon-two.png"}
            style={{
              width: "10vw",
            }}
          />
          <Text c={"#2B71B9"} fw={800} style={{ fontSize: 40 }}>
            10+
          </Text>
          <Text size="sm" c={"#2B71B9"} fw={400}>
            Experienced Employees{" "}
          </Text>
        </Stack>

        {/* <Stack gap={0} align="center">
          <Image
            src={"/images/trophy-icon.png"}
            style={{
              width: "10vw",
            }}
          />
          <Text c={"#2B71B9"} fw={800} style={{ fontSize: 40 }}>
            10+
          </Text>
          <Text size="sm" c={"#2B71B9"} fw={400}>
            Awards Winner{" "}
          </Text>
        </Stack> */}
      </Flex>
      <Space h={"md"} />
      <Center py={20}>
        <Anchor
          style={{
            textDecoration: "none",
          }}
          href="#our-services"
        >
          <div
            style={{
              padding: "10px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F21A27",
            }}
          >
            <ArrowUp size={20} color="#FFF" />
          </div>
        </Anchor>
      </Center>
    </Container>
  );
}

export default MobileCustomersSection;
