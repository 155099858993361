import {
  Box,
  Card,
  Container,
  Divider,
  Flex,
  Group,
  Image,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";

function MobileCompanyStatementSection() {
  return (
    <Container fluid px={0} py={"xl"} id="company-statement">
      <Flex
        direction={"column"}
        style={{
          height: "100%",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "50vh",
          }}
        >
          <Image
            src={"/images/statement-two.png"}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
          <Card
            style={{
              backgroundColor: "#F21A27",
              zIndex: 9,
              width: "fit-content",
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
            radius={0}
            px={40}
            py={"xs"}
          >
            <Group gap={10}>
              <Text style={{ fontSize: 40 }} span c={"#FFF"} fw={700}>
                7+
              </Text>
              <Text size="md" span c={"#FFF"} fw={500}>
                Years Experience
              </Text>
            </Group>
          </Card>
        </div>

        <Stack gap={32} align="center" p={"xl"}>
          <Flex direction={"column"} align={"center"}>
            <Group>
              <Box
                style={{
                  height: "35px",
                  width: "55px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/images/vision-icon.png"}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  alt=""
                />
              </Box>
              <Text style={{ fontSize: 32 }} fw={700} c={"#2B71B9"} ta="center">
                Vision
              </Text>
            </Group>
            <Space h={"xs"} />
            <Text size="md" c={"#676767"} ta="center">
              To be the one stop shop solution for managing and conserving water
              resources.
            </Text>
          </Flex>
          <Flex direction={"column"} align={"center"}>
            <Group>
              <Box
                style={{
                  height: "50px",
                  width: "50px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/images/mission-icon.png"}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  alt=""
                />
              </Box>
              <Text style={{ fontSize: 32 }} fw={700} c={"#2B71B9"}>
                Mission
              </Text>
            </Group>
            <Space h={"xs"} />
            <Text size="md" c={"#676767"} ta={"center"}>
              We aspire to exceed the customer expectations by providing
              efficient and outstanding services.
            </Text>
            <Space h={"xs"} />
            <Text size="md" c={"#676767"} ta={"center"}>
              The shareholders and management of the company maintain high level
              professionalism while discharging their mandates.
            </Text>
            <Space h={"xs"} />
            <Text size="md" c={"#676767"} ta={"center"}>
              The company networks with other stakeholders to preserve, conserve
              and sustain water resources to bequeath hope to the future
              generations.
            </Text>
          </Flex>
          <Flex direction={"column"} align={"center"}>
            <Group>
              <Box
                style={{
                  height: "45px",
                  width: "70px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/images/core-values-icon.png"}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  alt=""
                />
              </Box>
              <Text style={{ fontSize: 32 }} fw={700} c={"#2B71B9"}>
                Core Values
              </Text>
            </Group>
            <Space h={"xs"} />
            <Group justify="center">
              <Text size="md" c={"#676767"} ta={"center"}>
                Commitment
              </Text>
              <Divider orientation="vertical" size="sm" c={"#676767"} />
              <Text size="md" c={"#676767"} ta={"center"}>
                Integrity
              </Text>
              <Divider orientation="vertical" size="sm" c={"#676767"} />
              <Text size="md" c={"#676767"} ta={"center"}>
                Customer focus
              </Text>
            </Group>
          </Flex>
        </Stack>
      </Flex>
    </Container>
  );
}

export default MobileCompanyStatementSection;
