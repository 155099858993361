import {
  Box,
  Card,
  Center,
  Container,
  Flex,
  Grid,
  Image,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";

function ServiceDetails({ serviceDetails, serviceList }) {
  const navigate = useNavigate();

  return (
    <Container
      fluid
      px={"xl"}
      py={"xl"}
      style={{
        width: "100%",
      }}
      id="service-details"
    >
      <Grid gutter={64}>
        <Grid.Col span={9}>
          <Box style={{ height: "30rem" }}>
            <Grid>
              <Grid.Col span={8}>
                <Image
                  src={serviceDetails?.images?.firstUrl}
                  style={{
                    height: "30rem",
                  }}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Flex
                  direction={"column"}
                  justify={"space-between"}
                  gap={10}
                  style={{ height: "30rem" }}
                >
                  <Image
                    src={serviceDetails?.images?.secondUrl}
                    style={{
                      height: "19rem",
                      width: "100%",
                    }}
                  />
                  <Image
                    src={serviceDetails?.images?.thirdUrl}
                    style={{
                      height: "10rem",
                      width: "100%",
                    }}
                  />
                </Flex>
              </Grid.Col>
            </Grid>
          </Box>
          <Space h={"xl"} />
          <Text
            c={"#2B71B9"}
            fw={700}
            style={{
              fontSize: "2.5vw",
              lineHeight: 1.2,
            }}
          >
            Here at GIBRO We Turn Your Water Dreams Into Reality!
          </Text>
          <Space h={"md"} />
          <Text
            c={"#2B71B9"}
            fw={700}
            style={{
              fontSize: "1.5vw",
              lineHeight: 1.2,
            }}
          >
            Our expert team specializes in drilling boreholes, providing you
            with a reliable and sustainable water source for your home, farm, or
            business. 
          </Text>
          <Space h={"md"} />
          <Stack gap={32}>
            <Text c={"#676767"}>
              Imagine the convenience of having your own on-site water supply,
              free from the constraints of municipal sources. Our cutting-edge
              drilling technology ensures precision and efficiency, while our
              commitment to quality guarantees a long-lasting solution.
            </Text>
            <Text c={"#676767"}>
              Say goodbye to water scarcity worries and hello to
              self-sufficiency! At GIBRO, we pride ourselves on delivering
              excellence in every borehole we drill, equipping you with the
              life-changing resource beneath your feet.
            </Text>
            <Text c={"#676767"}>
              Choose us for unmatched expertise, reliability, and a water
              solution that lasts a lifetime. Let's drill down to your water
              needs and make waves of positive change together!
            </Text>
          </Stack>
          <Space h={"xl"} />
        </Grid.Col>
        <Grid.Col span={3}>
          <Stack gap={16}>
            {serviceList?.map((service, index) => (
              <Card
                key={index}
                radius={0}
                style={{
                  backgroundColor: "#2B71B933",
                  color: "#2B71B9",
                  height: "7rem",
                  cursor: "pointer",
                }}
                onClick={() => navigate(service?.link)}
              >
                <Center style={{ height: "100%" }}>
                  <Text
                    ta={"center"}
                    fw={700}
                    style={{
                      fontSize: "1vw",
                    }}
                  >
                    {service?.title}
                  </Text>
                </Center>
              </Card>
            ))}
          </Stack>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export default ServiceDetails;
