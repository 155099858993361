import { Box } from "@mantine/core";
import React, { useEffect, useState } from "react";
import ServiceDetailNavBar from "../../components/navigation-bar/ServiceDetailNavBar";
import BreadCrumb from "./components/breadcrumb/BreadCrumb";
import ServiceDetails from "./components/details/ServiceDetails";
import Footer from "./components/footer/Footer";
import { useParams } from "react-router-dom";
import { services } from "../../constants/services";
import MobileServiceLayout from "../../components/layout/MobileServiceLayout";
import MobileBreadCrumb from "./components/breadcrumb/MobileBreadCrumb";
import MobileServiceDetails from "./components/details/MobileServiceDetails";
import MobileFooter from "./components/footer/MobileFooter";

function ServiceDetailPage() {
  const { id } = useParams();

  const [serviceDetails, setServiceDetails] = useState({});
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    if (id) {
      let serviceObj = services?.find((service) => service?.id === String(id));

      setServiceDetails(serviceObj);

      let serviceListArr = services?.filter(
        (service) => service?.id !== String(id)
      );

      setServiceList(serviceListArr);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [id]);

  return (
    <>
      <Box visibleFrom="lg">
        <ServiceDetailNavBar />
        <BreadCrumb serviceDetails={serviceDetails} />
        <ServiceDetails
          serviceDetails={serviceDetails}
          serviceList={serviceList}
        />
        <Footer />
      </Box>
      <Box hiddenFrom="lg">
        <MobileServiceLayout />
        <MobileBreadCrumb serviceDetails={serviceDetails} />
        <MobileServiceDetails
          serviceDetails={serviceDetails}
          serviceList={serviceList}
        />
        <MobileFooter />
      </Box>
    </>
  );
}

export default ServiceDetailPage;
