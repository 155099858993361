import { BackgroundImage, Flex, Group, Text } from "@mantine/core";
import { CaretRight } from "@phosphor-icons/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function MobileBreadCrumb({ serviceDetails }) {
  const navigate = useNavigate();

  return (
    <BackgroundImage
      src="/images/services/service-bg-one.png"
      style={{
        minHeight: "11rem",
        paddingTop: "100px",
      }}
    >
      <BackgroundImage
        src="/images/services/service-bg-two.png"
        style={{
          height: "100%",
        }}
      >
        <Flex
          justify={"center"}
          direction={"column"}
          p={16}
          style={{
            height: "100%",
          }}
        >
          <Text c={"#FFF"} style={{ fontSize: "4vw" }} fw={700}>
            {serviceDetails?.title}
          </Text>
          <Group gap={10}>
            <Text
              c={"#FFF"}
              fw={600}
              style={{ fontSize: "2.5vw", cursor: "pointer" }}
              onClick={() => navigate("/#home")}
            >
              Home
            </Text>
            <CaretRight size={20} style={{ color: "#FFF" }} />
            <Text
              c={"#FFF"}
              fw={600}
              style={{ fontSize: "2.5vw", cursor: "pointer" }}
              onClick={() => navigate("/#our-services")}
            >
              Services
            </Text>
            <CaretRight size={20} style={{ color: "#FFF" }} />
            <Text c={"#FFE602"} fw={600} style={{ fontSize: "2.5vw" }}>
              {serviceDetails?.title}
            </Text>
          </Group>
        </Flex>
      </BackgroundImage>
    </BackgroundImage>
  );
}

export default MobileBreadCrumb;
