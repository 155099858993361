import { Card, Flex, Image, Stack, Text, Anchor } from "@mantine/core";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function MobileServiceDetailsNavBar({ toggle }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Stack gap={0}>
        <Card
          style={{
            backgroundColor: "#D9D9D94D",
          }}
          radius={0}
        >
          <Anchor
            href="/#home"
            style={{
              textDecoration: "none",
              color:
                location?.hash === "#home" || location?.hash === ""
                  ? "#F21A27"
                  : "#2B71B9",
            }}
            onClick={toggle}
          >
            <Text size="sm" fw={700} ta={"center"}>
              Home
            </Text>
          </Anchor>
        </Card>
        <Card
          style={{
            backgroundColor: "#FFF",
          }}
          radius={0}
        >
          <Anchor
            href="/#about-us"
            style={{
              textDecoration: "none",
              color: location?.hash === "#about-us" ? "#F21A27" : "#2B71B9",
            }}
            onClick={toggle}
          >
            <Text size="sm" fw={700} ta={"center"}>
              About Us
            </Text>
          </Anchor>
        </Card>
        <Card
          style={{
            backgroundColor: "#D9D9D94D",
          }}
          radius={0}
        >
          <Anchor
            href="/#our-services"
            style={{
              textDecoration: "none",
              color: location?.hash === "#our-services" ? "#F21A27" : "#2B71B9",
            }}
            onClick={toggle}
          >
            <Text size="sm" fw={700} ta={"center"}>
              Our Services
            </Text>
          </Anchor>
        </Card>
        <Card
          style={{
            backgroundColor: "#FFF",
          }}
          radius={0}
        >
          <Anchor
            href="/#operation-hours"
            style={{
              textDecoration: "none",
              color:
                location?.hash === "#operation-hours" ? "#F21A27" : "#2B71B9",
            }}
            onClick={toggle}
          >
            <Text size="sm" fw={700} ta={"center"}>
              Operation Hours
            </Text>
          </Anchor>
        </Card>
        <Card
          style={{
            backgroundColor: "#F21A27",
          }}
          radius={0}
        >
          <Anchor
            href="#contact-us"
            style={{
              textDecoration: "none",
            }}
            onClick={() => {
              toggle();
              navigate("/#contact-us");
            }}
          >
            <Text size="sm" fw={700} ta={"center"} c={"#FFF"}>
              Contact Us
            </Text>
          </Anchor>
        </Card>
      </Stack>
      <Stack px={"md"} py={"lg"}>
        <Flex align={"center"} gap={16}>
          <Card
            withBorder
            radius={8}
            p={10}
            style={{
              height: "40px",
              width: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={"/images/phone-icon-blue.png"}
              fit="contain"
              w="auto"
              h={20}
            />
          </Card>
          <Text size="sm" fw={700} c={"#2B71B9"}>
            Phone number
          </Text>
        </Flex>
        <Flex align={"center"} gap={16}>
          <Card
            withBorder
            radius={8}
            p={10}
            style={{
              height: "40px",
              width: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={"/images/email-icon-blue.png"}
              fit="contain"
              w="auto"
              h={20}
            />
          </Card>
          <Text size="sm" fw={700} c={"#2B71B9"}>
            info@gibroenterprises.com
          </Text>
        </Flex>
        <Flex align={"center"} gap={16}>
          <Card
            withBorder
            radius={8}
            p={10}
            style={{
              height: "40px",
              width: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={"/images/location-icon-blue.png"}
              fit="contain"
              w="auto"
              h={22}
            />
          </Card>
          <Text size="sm" fw={700} c={"#2B71B9"}>
            P.O Box 818 - 00520,Ruai Nairobi
          </Text>
        </Flex>
      </Stack>
      <Flex justify={"center"} my={20}>
        <Flex align={"center"} gap={16}>
          <Anchor href="#" target="_blank">
            <Image src={"/images/facebook-icon-blue.png"} h={30} w={30} />
          </Anchor>
          <Anchor href="#" target="_blank">
            <Image src={"/images/linkedin-icon-blue.png"} h={30} w={30} />
          </Anchor>
          <Anchor href="#" target="_blank">
            <Image src={"/images/twitter-icon-blue.png"} h={30} w={30} />
          </Anchor>
          <Anchor href="#" target="_blank">
            <Image src={"/images/youtube-icon-blue.png"} h={30} w={30} />
          </Anchor>
          <Anchor href="#" target="_blank">
            <Image src={"/images/whatsapp-icon-blue.png"} h={30} w={30} />
          </Anchor>
        </Flex>
      </Flex>
    </>
  );
}

export default MobileServiceDetailsNavBar;
