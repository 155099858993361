import {
  Button,
  Center,
  Container,
  Flex,
  Space,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import React, { useState } from "react";
import classes from "../../../../styles/styles.module.css";
import { Form, Formik } from "formik";
import { contactUsSchema } from "../../../../schemas";
import emailjs from "@emailjs/browser";
import { ENV_CONSTANTS } from "../../../../constants";

function ContactUs() {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const submitData = async (values, resetForm) => {
    setLoading(true);

    var templateParams = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phone,
      description: values.description,
    };

    try {
      await emailjs.send(
        ENV_CONSTANTS.EMAILJS_SERVICE_ID,
        ENV_CONSTANTS.EMAILJS_TEMPLATE_ID,
        templateParams,
        {
          publicKey: ENV_CONSTANTS.EMAILJS_PUBLIC_KEY,
        }
      );
      setLoading(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
        resetForm();
      }, 2000);
    } catch (err) {
      setLoading(false);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 2000);
    }
  };

  return (
    <Container
      fluid
      px={"xl"}
      py={"md"}
      style={{
        width: "100%",
        backgroundColor: "#2B71B9",
      }}
      id="contact-us"
    >
      <Center>
        <Flex
          direction={"column"}
          style={{
            width: "50%",
          }}
        >
          <Text
            c={"#FFF"}
            style={{
              fontSize: "2.5vw",
            }}
            fw={700}
            ta={"center"}
            className={classes["service-title"]}
          >
            Contact Us
          </Text>
          <Space h={"xl"} />
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              description: "",
            }}
            onSubmit={(values, { resetForm }) => {
              submitData(values, resetForm);
            }}
            validationSchema={contactUsSchema}
          >
            {({ handleChange, values, errors }) => (
              <Form>
                <Flex gap={32}>
                  <TextInput
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    styles={{
                      input: {
                        minHeight: "2.6rem",
                      },
                      root: {
                        flex: 1,
                      },
                    }}
                    radius={0}
                    error={errors.firstName}
                  />
                  <TextInput
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    styles={{
                      input: {
                        minHeight: "2.6rem",
                      },
                      root: {
                        flex: 1,
                      },
                    }}
                    radius={0}
                    error={errors.lastName}
                  />
                </Flex>
                <Space h={"md"} />
                <Flex gap={32}>
                  <TextInput
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="Phone number"
                    type="number"
                    styles={{
                      input: {
                        minHeight: "2.6rem",
                      },
                      root: {
                        flex: 1,
                      },
                    }}
                    radius={0}
                    error={errors.phone}
                  />
                  <TextInput
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email address"
                    type="email"
                    styles={{
                      input: {
                        minHeight: "2.6rem",
                      },
                      root: {
                        flex: 1,
                      },
                    }}
                    radius={0}
                    error={errors.email}
                  />
                </Flex>
                <Space h={"md"} />
                <Textarea
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  styles={{
                    input: {
                      minHeight: "14rem",
                    },
                  }}
                  radius={0}
                />
                <Space h={"xl"} />
                <Center>
                  <Button
                    variant="filled"
                    color={isSuccess ? "green" : "#F21A27"}
                    radius="xl"
                    px={64}
                    type="submit"
                    loading={loading}
                  >
                    {isSuccess
                      ? "Sent successfully!!"
                      : isError
                      ? "Please try again"
                      : "Submit"}
                  </Button>
                </Center>
              </Form>
            )}
          </Formik>
        </Flex>
      </Center>
    </Container>
  );
}

export default ContactUs;
