export const services = [
  {
    image: "/images/services/list/service-one.png",
    title: "Borehole Drilling",
    // link: "/services/1",
    images: {
      firstUrl: "/images/services/list/service-one-one.png",
      secondUrl: "/images/services/list/service-one-two.png",
      thirdUrl: "/images/services/list/service-one-three.png",
    },
    id: "1",
  },
  {
    image: "/images/services/list/service-two.png",
    title: "Test Pumping & Borehole Equipping",
    // link: "/services/2",
    images: {
      firstUrl: "/images/services/list/service-two-one.png",
      secondUrl: "/images/services/list/service-two-two.png",
      thirdUrl: "/images/services/list/service-two-three.png",
    },
    id: "2",
  },
  {
    image: "/images/services/list/service-three.png",
    title: "Control Panel Assembly",
    // link: "/services/3",
    images: {
      firstUrl: "/images/services/list/service-three-one.png",
      secondUrl: "/images/services/list/service-three-two.png",
      thirdUrl: "/images/services/list/service-three-three.png",
    },
    id: "3",
  },
  {
    image: "/images/services/list/service-four.png",
    title: "Borehole Camera",
    // link: "/services/4",
    images: {
      firstUrl: "/images/services/list/service-four-one.png",
      secondUrl: "/images/services/list/service-four-two.png",
      thirdUrl: "/images/services/list/service-four-three.png",
    },
    id: "4",
  },
  {
    image: "/images/services/list/service-five.png",
    title: "Borehole Maintenance",
    // link: "/services/5",
    images: {
      firstUrl: "/images/services/list/service-five-one.png",
      secondUrl: "/images/services/list/service-five-two.png",
      thirdUrl: "/images/services/list/service-five-three.png",
    },
    id: "5",
  },
  {
    image: "/images/services/list/service-six.png",
    title: "Solar Power Installation for Boreholes",
    // link: "/services/6",
    images: {
      firstUrl: "/images/services/list/service-six-one.png",
      secondUrl: "/images/services/list/service-six-two.png",
      thirdUrl: "/images/services/list/service-six-three.png",
    },
    id: "6",
  },
  {
    image: "/images/services/list/service-seven.png",
    title: "Installation water Decolourisation",
    // link: "/services/7",
    images: {
      firstUrl: "/images/services/list/service-seven-one.png",
      secondUrl: "/images/services/list/service-seven-two.png",
      thirdUrl: "/images/services/list/service-seven-three.png",
    },
    id: "7",
  },
  {
    image: "/images/services/list/service-eight.png",
    title: "Installation of Water Decolourisaton System (fe removal)",
    // link: "/services/8",
    images: {
      firstUrl: "/images/services/list/service-eight-one.png",
      secondUrl: "/images/services/list/service-eight-two.png",
      thirdUrl: "/images/services/list/service-eight-three.png",
    },
    id: "8",
  },
  {
    image: "/images/services/list/service-nine.png",
    title: "Installation of Water Softening Systems (Ca Mg removal)",
    // link: "/services/9",
    images: {
      firstUrl: "/images/services/list/service-nine-one.png",
      secondUrl: "/images/services/list/service-nine-two.png",
      thirdUrl: "/images/services/list/service-nine-three.png",
    },
    id: "9",
  },
  {
    image: "/images/services/list/service-ten.png",
    title: "Core Drilling For Pile Foundations",
    // link: "/services/10",
    images: {
      firstUrl: "/images/services/list/service-ten-one.png",
      secondUrl: "/images/services/list/service-ten-two.png",
      thirdUrl: "/images/services/list/service-ten-three.png",
    },
    id: "10",
  },
  {
    image: "/images/services/list/service-eleven.png",
    title: "Fluoride Removal to Acceptable Levels For Human Consumption",
    // link: "/services/11",
    images: {
      firstUrl: "/images/services/list/service-eleven-one.png",
      secondUrl: "/images/services/list/service-eleven-two.png",
      thirdUrl: "/images/services/list/service-eleven-three.png",
    },
    id: "11",
  },
  {
    image: "/images/services/list/service-twelve.png",
    title: "Elevated tanks and Tower Engineering",
    // link: "/services/12",
    images: {
      firstUrl: "/images/services/list/service-twelve-one.png",
      secondUrl: "/images/services/list/service-twelve-two.png",
      thirdUrl: "/images/services/list/service-twelve-three.png",
    },
    id: "12",
  },
];
