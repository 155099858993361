// @ts-nocheck
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import HomePage from "./views/home-page";
import ServiceDetailPage from "./views/service-details";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<HomePage />} />
      <Route path="services/:id" element={<ServiceDetailPage />} />
    </>
  )
);
