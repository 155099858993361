import * as Yup from "yup";

export const contactUsSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phone: Yup.number().required("Required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Required"),
  description: Yup.string(),
});
