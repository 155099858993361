import React from "react";
import {
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Space,
  Stack,
  Text,
  Box,
  Card,
} from "@mantine/core";

function CompanyStatementSection() {
  return (
    <Container
      fluid
      p={64}
      style={{
        minHeight: "89vh",
        width: "100%",
      }}
      id="company-statement"
    >
      <Grid style={{ width: "100%" }} gutter={64}>
        <Grid.Col span={6} style={{ width: "100%" }}>
          <Grid>
            <Grid.Col span={5}>
              <Image
                src={"/images/statement-one.png"}
                style={{
                  width: "100%",
                  height: "40vh",
                }}
              />
            </Grid.Col>
            <Grid.Col span={7}>
              <Image
                src={"/images/statement-two.png"}
                style={{
                  width: "100%",
                  height: "50vh",
                }}
              />
            </Grid.Col>
          </Grid>
          <Image
            src={"/images/statement-three.png"}
            style={{
              height: "30vh",
              position: "absolute",
              zIndex: 8,
              marginTop: "-3rem",
              width: "25vw",
            }}
          />
          <Card
            style={{
              backgroundColor: "#F21A27",
              zIndex: 9,
              width: "fit-content",
              position: "absolute",
              marginLeft: "18vw",
              marginTop: "-1rem",
            }}
            radius={0}
            px={40}
            py={"xs"}
          >
            <Group gap={10}>
              <Text style={{ fontSize: 40 }} span c={"#FFF"} fw={700}>
                7+
              </Text>
              <Text size="md" span c={"#FFF"} fw={500}>
                Years Experience
              </Text>
            </Group>
          </Card>
        </Grid.Col>
        <Grid.Col span={6}>
          <Stack gap={32}>
            <div>
              <Group>
                <Box
                  style={{
                    height: "40px",
                    width: "70px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/images/vision-icon.png"}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    alt=""
                  />
                </Box>
                <Text style={{ fontSize: "2vw" }} fw={700} c={"#2B71B9"}>
                  Vision
                </Text>
              </Group>
              <Space h={"xs"} />
              <Text size="md" c={"#676767"}>
                To be the one stop shop solution for managing and conserving
                water resources.
              </Text>
            </div>
            <div>
              <Group>
                <Box
                  style={{
                    height: "60px",
                    width: "70px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/images/mission-icon.png"}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    alt=""
                  />
                </Box>
                <Text style={{ fontSize: "2vw" }} fw={700} c={"#2B71B9"}>
                  Mission
                </Text>
              </Group>
              <Space h={"xs"} />
              <Text size="md" c={"#676767"}>
              To exceed customer expectations through exceptional services while upholding professionalism, collaborating with stakeholders to conserve and sustain water resources for future generations.
              </Text>
              <Space h={"xs"} />
              {/* <Text size="md" c={"#676767"}>
                The shareholders and management of the company maintain high
                level professionalism while discharging their mandates.
              </Text>
              <Space h={"xs"} />
              <Text size="md" c={"#676767"}>
                The company networks with other stakeholders to preserve,
                conserve and sustain water resources to bequeath hope to the
                future generations.
              </Text> */}
            </div>
            <div>
              <Group>
                <Box
                  style={{
                    height: "45px",
                    width: "70px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/images/core-values-icon.png"}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    alt=""
                  />
                </Box>
                <Text style={{ fontSize: "2vw" }} fw={700} c={"#2B71B9"}>
                  Core Values
                </Text>
              </Group>
              <Space h={"xs"} />
              <Group>
                <Text size="md" c={"#676767"}>
                  Commitment
                </Text>
                <Divider orientation="vertical" size="sm" c={"#676767"} />
                <Text size="md" c={"#676767"}>
                  Integrity
                </Text>
                <Divider orientation="vertical" size="sm" c={"#676767"} />
                <Text size="md" c={"#676767"}>
                  Customer focus
                </Text>
              </Group>
            </div>
          </Stack>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export default CompanyStatementSection;
