import { BackgroundImage, Box } from "@mantine/core";
import React, { useEffect } from "react";
import NavigationBar from "../../components/navigation-bar/NavigationBar";
import HomeSection from "./components/home/HomeSection";
import MobileLayout from "../../components/layout/MobileLayout";
import MobileHomeSection from "./components/home/MobileHomeSection";
import CompanyStatementSection from "./components/company-statement/CompanyStatementSection";
import MobileCompanyStatementSection from "./components/company-statement/MobileCompanyStatementSection";
import AboutUsSection from "./components/about-us/AboutUsSection";
import MobileAboutUsSection from "./components/about-us/MobileAboutUsSection";
import ServicesSection from "./components/services/ServicesSection";
import MobileServicesSection from "./components/services/MobileServicesSection";
import MachineSection from "./components/machine/MachineSection";
import MobileMachineSection from "./components/machine/MobileMachineSection";
import CustomersSection from "./components/customers/CustomersSection";
import MobileCustomersSection from "./components/customers/MobileCustomersSection";
import OperationHours from "./components/operation-hours/OperationHours";
import MobileOperationHours from "./components/operation-hours/MobileOperationHours";
import ContactUs from "./components/contact-us/ContactUs";
import MobileContactUs from "./components/contact-us/MobileContactUs";
import Footer from "./components/footer/Footer";
import MobileFooter from "./components/footer/MobileFooter";
import { useLocation } from "react-router-dom";

function HomePage() {
  const location = useLocation();
  const targetId = location?.hash ? location?.hash?.replace(/#/g, "") : "";

  useEffect(() => {
    if (targetId) {
      const el = document.getElementById(targetId);

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [targetId]);

  return (
    <>
      <Box visibleFrom="lg">
        <BackgroundImage
          src="/images/home-page-image.png"
          style={{
            height: "100vh",
          }}
        >
          <BackgroundImage
            src="/images/home-page-vectors.png"
            style={{
              height: "100vh",
            }}
          >
            <NavigationBar />
            <HomeSection />
          </BackgroundImage>
        </BackgroundImage>
        <CompanyStatementSection />
        <AboutUsSection />
        <ServicesSection />
        <MachineSection />
        <CustomersSection />
        <OperationHours />
        <ContactUs />
        <Footer />
      </Box>
      <Box hiddenFrom="lg">
        <BackgroundImage
          src="/images/home-page-mobile-image.png"
          style={{
            height: "100vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
          }}
        >
          <BackgroundImage
            src="/images/home-page-mobile-vectors.png"
            style={{
              height: "100vh",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100%",
            }}
          >
            <MobileLayout />
            <MobileHomeSection />
          </BackgroundImage>
        </BackgroundImage>
        <MobileCompanyStatementSection />
        <MobileAboutUsSection />
        <MobileServicesSection />
        <MobileMachineSection />
        <MobileCustomersSection />
        <MobileOperationHours />
        <MobileContactUs />
        <MobileFooter />
      </Box>
    </>
  );
}

export default HomePage;
