import { Container, Flex, Image, Space, Stack, Text } from "@mantine/core";
import React from "react";

function MachineSection() {
  return (
    <Container
      fluid
      p={0}
      style={{
        width: "100%",
      }}
      id="drilling-rig"
    >
      <Flex gap={0}>
        <Image
          src="/images/drilling-rig.png"
          style={{
            height: "100%",
            width: "40%",
          }}
        />

        <Container fluid p={"xl"} bg={"#F21A27"}>
          <Flex direction={"column"}>
            <Text
              c={"#FFF"}
              style={{
                textDecorationColor: "#2B71B9",
                fontSize: "2.5vw",
                textUnderlineOffset: "15px",
                textDecorationLine: "underline",
                textDecorationThickness: "6px",
              }}
              fw={700}
            >
              Our Drilling Rig machine
            </Text>
            <Space h={"xl"} />
            <Stack gap={20}>
              <Text c={"#FFF"}>
                GEL owns a Drilling Rig machine that has a capacity to drill a
                600m deep borehole. It has the capability to drill a 450mm
                diameter hole. GEL also owns a mud pump allowing it to perform
                safe mud drilling operations, in areas with extremely loose
                formation.
              </Text>
              <Text c={"#FFF"}>
                The team also employs drill-tracking techniques using mobile
                technology to avail management and the client with live data,
                showing progress throughout the process. GEL also owns a range
                of generators ready for hire. It boasts of power generation from
                40KVA up to 220 KVA, depending on the power requirements. This
                allows GEL to conduct test pumping even with heavy duty pumps.
              </Text>
              <Text c={"#FFF"}>
                The company also owns a state of the art test-pumping unit
                capable of handling a rated weight of 10 tonnes. The machine
                employs technology that allows installation of submersible water
                pumps as deep as 400m. This means GI galvanized pipes can
                comfortably and safely be installed in your well.
              </Text>
            </Stack>
          </Flex>
        </Container>
      </Flex>
    </Container>
  );
}

export default MachineSection;
