import React from "react";
import { AppShell, Burger, Group, Image } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import MobileServiceDetailsNavbar from "../navigation-bar/MobileServiceDetailsNavbar";

function MobileServiceLayout() {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 100 }}
      navbar={{
        width: "100%",
        breakpoint: "sm",
        collapsed: { mobile: !opened, desktop: !opened },
      }}
      padding="md"
    >
      <AppShell.Header style={{ backgroundColor: "#FFF", border: "none" }}>
        <Group h="100%" px="md" justify="space-between">
          <Image src={"/images/colored-logo.png"} h={80} w={100} />
          <Burger
            opened={opened}
            onClick={toggle}
            size="lg"
            color={"#F21A27"}
          />
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p={0}>
        <MobileServiceDetailsNavbar toggle={toggle} />
      </AppShell.Navbar>
      {/* <AppShell.Main>{Children}</AppShell.Main> */}
    </AppShell>
  );
}

export default MobileServiceLayout;
