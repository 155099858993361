import {
  Box,
  Card,
  Container,
  Flex,
  Group,
  Image,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";

function MobileServiceDetails({ serviceDetails, serviceList }) {
  const navigate = useNavigate();

  return (
    <Container
      fluid
      px={"md"}
      py={"md"}
      style={{
        width: "100%",
      }}
      id="service-details"
    >
      <Flex gap={10} direction={"column"}>
        <Image
          src={serviceDetails?.images?.firstUrl}
          style={{
            width: "100%",
            height: "20rem",
          }}
        />
        <Flex gap={10} style={{}}>
          <Image
            src={serviceDetails?.images?.secondUrl}
            style={{
              height: "9rem",
              flex: 1,
              width: "48%",
            }}
          />
          <Image
            src={serviceDetails?.images?.thirdUrl}
            style={{
              height: "9rem",
              flex: 1,
              width: "48%",
            }}
          />
        </Flex>
      </Flex>
      <Space h={"xl"} />
      <Text
        c={"#2B71B9"}
        fw={700}
        style={{
          fontSize: "6vw",
          lineHeight: 1.2,
        }}
      >
        Here at GIBRO We Turn Your Water Dreams Into Reality!
      </Text>
      <Space h={"xl"} />
      <Card radius={15} shadow="sm" py={"xl"} withBorder>
        <Stack gap={32}>
          <Text c={"#676767"} size="sm">
            Imagine the convenience of having your own on-site water supply,
            free from the constraints of municipal sources. Our cutting-edge
            drilling technology ensures precision and efficiency, while our
            commitment to quality guarantees a long-lasting solution.
          </Text>
          <Text c={"#676767"} size="sm">
            Say goodbye to water scarcity worries and hello to self-sufficiency!
            At GIBRO, we pride ourselves on delivering excellence in every
            borehole we drill, equipping you with the life-changing resource
            beneath your feet.
          </Text>
          <Text c={"#676767"} size="sm">
            Choose us for unmatched expertise, reliability, and a water solution
            that lasts a lifetime. Let's drill down to your water needs and make
            waves of positive change together!
          </Text>
        </Stack>
      </Card>
      <Space h={"md"} />
      <Stack gap={16}>
        {serviceList?.map((service, index) => (
          <Card
            key={index}
            radius={0}
            style={{
              backgroundColor: "#2B71B933",
              color: "#2B71B9",
              minHeight: "4rem",
              cursor: "pointer",
            }}
            onClick={() => navigate(service?.link)}
          >
            <Group
              justify="space-between"
              align="center"
              gap={10}
              style={{ height: "100%" }}
            >
              <Box
                style={{
                  width: "calc(100% - 50px)",
                }}
              >
                <Text
                  fw={700}
                  style={{
                    fontSize: "3.5vw",
                  }}
                  lineClamp={1}
                >
                  {service?.title}
                </Text>
              </Box>
              <Image
                src={"/images/arrow-right-icon-blue.png"}
                style={{
                  width: "35px",
                }}
              />
            </Group>
          </Card>
        ))}
      </Stack>
    </Container>
  );
}

export default MobileServiceDetails;
