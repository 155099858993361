import {
  Button,
  Center,
  Container,
  Space,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import classes from "../../../../styles/styles.module.css";
import { ENV_CONSTANTS } from "../../../../constants";
import emailjs from "@emailjs/browser";
import { contactUsSchema } from "../../../../schemas";

function MobileContactUs() {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const submitData = async (values, resetForm) => {
    setLoading(true);

    var templateParams = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phone,
      description: values.description,
    };

    try {
      await emailjs.send(
        ENV_CONSTANTS.EMAILJS_SERVICE_ID,
        ENV_CONSTANTS.EMAILJS_TEMPLATE_ID,
        templateParams,
        {
          publicKey: ENV_CONSTANTS.EMAILJS_PUBLIC_KEY,
        }
      );
      setLoading(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
        resetForm();
      }, 2000);
    } catch (err) {
      setLoading(false);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 2000);
    }
  };

  return (
    <Container
      fluid
      px={"xl"}
      py={"md"}
      style={{
        width: "100%",
        backgroundColor: "#2B71B9",
      }}
      id="contact-us"
    >
      <Text
        c={"#FFF"}
        fw={700}
        ta={"center"}
        className={classes["service-title"]}
      >
        Contact Us
      </Text>
      <Space h={"xl"} />
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          description: "",
        }}
        onSubmit={(values, { resetForm }) => {
          submitData(values, resetForm);
        }}
        validationSchema={contactUsSchema}
      >
        {({ handleChange, values, errors }) => (
          <Form>
            <Stack gap={16}>
              <TextInput
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                placeholder="First Name"
                styles={{
                  input: {
                    minHeight: "2.6rem",
                  },
                  root: {
                    flex: 1,
                  },
                }}
                radius={0}
                error={errors.firstName}
              />
              <TextInput
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                styles={{
                  input: {
                    minHeight: "2.6rem",
                  },
                  root: {
                    flex: 1,
                  },
                }}
                radius={0}
                error={errors.lastName}
              />
              <TextInput
                name="phone"
                value={values.phone}
                onChange={handleChange}
                placeholder="Phone number"
                type="number"
                styles={{
                  input: {
                    minHeight: "2.6rem",
                  },
                  root: {
                    flex: 1,
                  },
                }}
                radius={0}
                error={errors.phone}
              />
              <TextInput
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Email address"
                type="email"
                styles={{
                  input: {
                    minHeight: "2.6rem",
                  },
                  root: {
                    flex: 1,
                  },
                }}
                radius={0}
                error={errors.email}
              />
              <Textarea
                name="description"
                value={values.description}
                onChange={handleChange}
                styles={{
                  input: {
                    minHeight: "10rem",
                  },
                }}
                radius={0}
              />
            </Stack>
            <Space h={"xl"} />
            <Center>
              <Button
                variant="filled"
                color={isSuccess ? "green" : "#F21A27"}
                radius="xl"
                px={64}
                type="submit"
                loading={loading}
              >
                {isSuccess
                  ? "Sent successfully!!"
                  : isError
                  ? "Please try again"
                  : "Submit"}
              </Button>
            </Center>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default MobileContactUs;
