import { Card, Container, Flex, Space, Text } from "@mantine/core";
import React from "react";

function OperationHours() {
  return (
    <Container
      fluid
      p={64}
      style={{
        width: "100%",
      }}
      id="operation-hours"
    >
      <Flex justify={"center"}>
        <Card
          radius={0}
          style={{
            backgroundColor: "#F21A27",
            minWidth: "60vw",
          }}
          py={"xl"}
        >
          <Text
            ta={"center"}
            c={"#FFF"}
            fw={700}
            style={{
              fontSize: "2.5vw",
            }}
          >
            Operation Hours
          </Text>
          <Space h={"md"} />
          <Text ta={"center"} c={"#FFF"} style={{
              fontSize: "1.5vw",
            }}>
            <b>8:00 AM</b> - <b>5:00 PM</b> : Monday to Friday | <b>9:00 AM</b>{" "}
            - <b>12:00 PM</b> : Saturday
          </Text>
          <Space h={"sm"} />
          <Text ta={"center"} c={"#FFF"} size="sm">
            In case of emergency work, there is a GEL team available to provide
            services 24/7
          </Text>
        </Card>
      </Flex>
    </Container>
  );
}

export default OperationHours;
