import {
  Container,
  Flex,
  Space,
  Stack,
  Text,
  Image,
  Anchor,
  Center,
} from "@mantine/core";
import { ArrowUp } from "@phosphor-icons/react";
import React from "react";
import classes from "../../../../styles/styles.module.css";

function MobileAboutUsSection() {
  return (
    <Container
      fluid
      p={0}
      style={{
        width: "100%",
      }}
      id="about-us"
    >
      <Flex gap={0} direction={"column"}>
        <Container fluid p={"xl"} bg={"#2B71B9"}>
          <Text
            c={"#FFF"}
            style={{
              textDecorationColor: "#F21A27",
              textUnderlineOffset: "15px",
              textDecorationLine: "underline",
              textDecorationThickness: "6px",
            }}
            fw={700}
            ta={"center"}
            className={classes["about-us-title"]}
          >
            About Us
          </Text>
          <Space h={"xl"} />
          <Stack gap={20}>
           <Text c={"#FFF"}>
                           Gathering Investment and Bridging Resources for Opportunities (GIBRO) Enterprise Limited
                         </Text>
                         <Text c={"#FFF"}>
                           GIBRO Enterprise Limited (GEL) is committed to transforming water resource management and fostering a sustainable future in Kenya. Leveraging advanced groundwater development equipment and a dedicated team of professionals, we deliver innovative solutions to improve water access, treatment, and conservation.
                         </Text>
                         <Text c={"#FFF"}>
                         Water scarcity remains a pressing issue in Kenya, with millions lacking access to clean water. At GEL, we address this challenge through sustainable approaches, including groundwater development, water treatment, and storage solutions. Our state-of-the-art drilling unit has successfully developed water wells in underserved urban areas, bringing clean water to communities in critical need.
                         </Text>
                         <Text c={"#FFF"}>
                         Collaboration drives our success. We work closely with communities, government bodies, and organizations to promote sustainable water practices that secure resources for future generations. By combining cutting-edge technology with local involvement, we ensure our projects are impactful and long-lasting.
                         </Text>
                         <Text c={"#FFF"}>
                         At GIBRO, we are more than a water solutions provider—we are partners in building a better future. By choosing us, you invest in clean water access, community empowerment, and environmental stewardship. Together, we can create a Kenya where clean, safe water is accessible to all.
                         </Text>
          </Stack>
          <Space h={"xl"} />
        </Container>

        <Image
          src="/images/about-us-one.png"
          style={{
            height: "10rem",
            width: "100%",
          }}
        />

        <Center py={20}>
          <Anchor
            style={{
              textDecoration: "none",
            }}
            href="#company-statement"
          >
            <div
              style={{
                padding: "10px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F21A27",
              }}
            >
              <ArrowUp size={20} color="#FFF" />
            </div>
          </Anchor>
        </Center>
      </Flex>
    </Container>
  );
}

export default MobileAboutUsSection;
