import { Anchor, Box, Button, Center, Flex, Image, Text } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";

function ServiceDetailNavBar() {
  const navigate = useNavigate();

  return (
    <Box
      style={{
        backgroundColor: "#FFF",
        position: "fixed",
        zIndex: 10,
        width: "100%",
        height: "5rem",
      }}
      px={"xl"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Flex align={"center"} my={"auto"} justify={"space-between"} gap={32}>
          <Image src={"/images/logo-one.png"} h={55} />
          <Anchor
            style={{
              textDecoration: "none",
            }}
            href="/#home"
          >
            <Text c={"#2B71B9"} size="lg" fw={700}>
              Home
            </Text>
          </Anchor>
          <Anchor
            style={{
              textDecoration: "none",
            }}
            href="/#about-us"
          >
            <Text c={"#2B71B9"} size="lg" fw={700}>
              About Us
            </Text>
          </Anchor>
          <Anchor
            style={{
              textDecoration: "none",
            }}
            href="/#our-services"
          >
            <Text c={"#2B71B9"} size="lg" fw={700}>
              Our Services
            </Text>
          </Anchor>
          <Anchor
            style={{
              textDecoration: "none",
            }}
            href="/#operation-hours"
          >
            <Text c={"#2B71B9"} size="lg" fw={700}>
              Operation Hours
            </Text>
          </Anchor>
          <Button
            variant="filled"
            radius={0}
            styles={{
              root: {
                color: "#FFF",
                backgroundColor: "#F21A27",
              },
            }}
            size="lg"
            onClick={() => navigate("/#contact-us")}
          >
            Contact Us
          </Button>
        </Flex>
      </div>
    </Box>
  );
}

export default ServiceDetailNavBar;
