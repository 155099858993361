import {
  Anchor,
  Container,
  Divider,
  Flex,
  Image,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";

function Footer() {
  return (
    <Container
      fluid
      px={"xl"}
      py={"xl"}
      style={{
        width: "100%",
        backgroundColor: "#2B71B9",
      }}
      id="service-footer"
    >
      <Flex gap={64} justify={"space-between"}>
        <Flex align={"start"}>
          <Image
            src={"/images/white-logo.png"}
            style={{
              width: "8vw",
            }}
          />
        </Flex>

        <Flex direction={"column"}>
          <Text
            fw={700}
            style={{
              fontSize: "1.5vw",
              cursor: "pointer",
            }}
            c={"#FFF"}
            onClick={() => {
              document.getElementById("about-us")?.scrollIntoView();
            }}
          >
            About Us
          </Text>
          <Space h={"md"} />
          <Stack gap={10}>
            <Text
              c={"#FFF"}
              size="lg"
              style={{ cursor: "pointer" }}
            >
              Mission
            </Text>
            <Divider color="#F21A27" size={"sm"} />
            <Text
              c={"#FFF"}
              size="lg"
              style={{ cursor: "pointer" }}
            >
              Vision
            </Text>
            <Divider color="#F21A27" size={"sm"} />
            <Text
              c={"#FFF"}
              size="lg"
              style={{ cursor: "pointer" }}
            >
              Core values
            </Text>
          </Stack>
        </Flex>

        <Flex direction={"column"}>
          <Text
            fw={700}
            style={{
              fontSize: "1.5vw",
            }}
            c={"#FFF"}
          >
            Location
          </Text>
          <Space h={"md"} />
          <Text c={"#FFF"} size="sm" fw={700}>
            Address
          </Text>
          <Text c={"#FFF"} size="sm">
            P.O Box 818 - 00520, Ruai , Nairobi
          </Text>
        </Flex>

        <Flex direction={"column"}>
          <Text
            fw={700}
            style={{
              fontSize: "1.5vw",
            }}
            c={"#FFF"}
          >
            Social Media
          </Text>
          <Space h={"md"} />
          <Flex align={"center"} gap={16}>
            <Anchor href="#" target="_blank">
              <Image src={"/images/facebook-icon.png"} h={22} w={22} />
            </Anchor>
            <Anchor href="#" target="_blank">
              <Image src={"/images/linkedin-icon.png"} h={22} w={22} />
            </Anchor>
            <Anchor href="#" target="_blank">
              <Image src={"/images/twitter-icon.png"} h={22} w={22} />
            </Anchor>
            <Anchor href="#" target="_blank">
              <Image src={"/images/youtube-icon.png"} h={22} w={22} />
            </Anchor>
            <Anchor href="#" target="_blank">
              <Image src={"/images/whatsapp-icon.png"} h={22} w={22} />
            </Anchor>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
}

export default Footer;
