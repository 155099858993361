import {
  BackgroundImage,
  Container,
  Flex,
  Space,
  Text,
  Image,
  Card,
  Group,
  Center,
  Anchor,
} from "@mantine/core";
import React from "react";
import classes from "../../../../styles/styles.module.css";
import { services } from "../../../../constants/services";
import { ArrowUp } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

function MobileServicesSection() {
  const navigate = useNavigate();

  return (
    <BackgroundImage
      src="/images/services-bg.png"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
      }}
    >
      <Container
        fluid
        px={"xl"}
        py={"md"}
        style={{
          width: "100%",
        }}
        id="our-services"
      >
        <Text
          c={"#2B71B9"}
          style={{
            textDecorationColor: "#F21A27",
            textUnderlineOffset: "15px",
            textDecorationLine: "underline",
            textDecorationThickness: "6px",
          }}
          fw={700}
          ta={"center"}
          className={classes["service-title"]}
        >
          Our services
        </Text>
        <Space h={"xl"} />
        <Flex gap={32} justify={"center"} wrap={"wrap"}>
          {services?.map((service, index) => (
            <div
              style={{
                position: "relative",
                height: "18rem",
                width: "15rem",
              }}
              className={classes["service-card"]}
              key={index}
            >
              <Image
                src={service.image}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
              <Card
                radius={0}
                className={classes["service-desc-card"]}
                onClick={() => {
                  navigate(service.link);
                }}
              >
                <Group justify="center">
                  <Text size="xs" ta={"center"} fw={700} c={"#FFF"}>
                    {service.title}
                  </Text>
                  <div className={classes["service-desc-arrow"]}>
                    <Image
                      src={"/images/arrow-right-icon.png"}
                      style={{
                        width: "35px",
                      }}
                    />
                  </div>
                </Group>
              </Card>
            </div>
          ))}
        </Flex>
      </Container>
      <Center py={20}>
        <Anchor
          style={{
            textDecoration: "none",
          }}
          href="#about-us"
        >
          <div
            style={{
              padding: "10px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F21A27",
            }}
          >
            <ArrowUp size={20} color="#FFF" />
          </div>
        </Anchor>
      </Center>
    </BackgroundImage>
  );
}

export default MobileServicesSection;
