import { Card, Container, Divider, Space, Text } from "@mantine/core";
import React from "react";
import classes from "../../../../styles/styles.module.css";

function MobileOperationHours() {
  return (
    <Container
      fluid
      px={"xl"}
      py={"xl"}
      style={{
        width: "100%",
      }}
      id="operation-hours"
    >
      <Card
        radius={0}
        style={{
          backgroundColor: "#F21A27",
          width: "100%",
        }}
        p={"xl"}
      >
        <Text
          ta={"center"}
          c={"#FFF"}
          fw={700}
          className={classes["customer-title"]}
          style={{
            lineHeight: 1.2,
          }}
        >
          Operation Hours
        </Text>
        <Space h={"md"} />
        <Text ta={"center"} c={"#FFF"}>
          <b>8:00 AM</b> - <b>5:00 PM</b>:
        </Text>
        <Text ta={"center"} c={"#FFF"}>
          Monday to Friday
        </Text>
        <Divider
          color="#FFF"
          my={20}
          style={{
            width: "70%",
          }}
          mx={"auto"}
        />
        <Text ta={"center"} c={"#FFF"}>
          <b>9:00 AM</b> - <b>12:00 PM</b>:
        </Text>
        <Text ta={"center"} c={"#FFF"}>
          Saturday
        </Text>
        <Space h={50} />
        <Text ta={"center"} c={"#FFF"} size="sm">
          In case of emergency work, there is a GEL team available to provide
          services 24/7
        </Text>
      </Card>
    </Container>
  );
}

export default MobileOperationHours;
