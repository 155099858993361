import { Container, Flex, Image, Space, Stack, Text } from "@mantine/core";
import React from "react";
import classes from "../../../../styles/styles.module.css";

function CustomersSection() {
  return (
    <Container
      fluid
      p={64}
      style={{
        width: "100%",
      }}
      id="customers"
    >
      <Flex justify={"center"} gap={16} align={"center"}>
        <Flex direction={"column"}>
          <Text
            c={"#2B71B9"}
            style={{
              fontSize: "2.5vw",
            }}
            fw={700}
            className={classes["customer-title"]}
          >
            Hundreds of Clients
          </Text>
          <Text
            c={"#2B71B9"}
            style={{
              fontSize: "2.5vw",
            }}
            fw={700}
            className={classes["customer-title"]}
          >
            Trust{" "}
            <Text
              span
              style={{
                textDecorationColor: "#F21A27",
                fontSize: "2.5vw",
                textUnderlineOffset: "15px",
                textDecorationLine: "underline",
                textDecorationThickness: "6px",
              }}
              fw={700}
              className={classes["customer-title"]}
            >
              Gibro
            </Text>
          </Text>
          <Space h={"xl"} />
          <Text c={"#676767"}>
            with its unparalleled service delivery and expertise in
          </Text>
          <Text c={"#676767"}>
            providing access to clean, sustainable water sources
          </Text>
          <Space
            style={{
              height: "14vh",
            }}
          />
          <Flex align={"center"} gap={64}>
            <Stack gap={0} align="center">
              <Image
                src={"/images/stack-icon.png"}
                style={{
                  width: "3vw",
                  height: "1vh",
                }}
              />
              <Text c={"#2B71B9"} fw={800} style={{ fontSize: 40 }}>
                70+
              </Text>
              <Text size="sm" c={"#2B71B9"} fw={400}>
                Projects completed{" "}
              </Text>
            </Stack>

            <Stack gap={0} align="center">
              <Image
                src={"/images/user-icon.png"}
                style={{
                  width: "3vw",
                  height: "1vh",
                }}
              />
              <Text c={"#2B71B9"} fw={800} style={{ fontSize: 40 }}>
                100+
              </Text>
              <Text size="sm" c={"#2B71B9"} fw={400}>
                Happy Customers{" "}
              </Text>
            </Stack>

            <Stack gap={0} align="center">
              <Image
                src={"/images/user-icon-two.png"}
                style={{
                  width: "3vw",
                  height: "1vh",
                }}
              />
              <Text c={"#2B71B9"} fw={800} style={{ fontSize: 40 }}>
                10+
              </Text>
              <Text size="sm" c={"#2B71B9"} fw={400}>
                Experienced Employees{" "}
              </Text>
            </Stack>
{/* 
            <Stack gap={0} align="center">
              <Image
                src={"/images/trophy-icon.png"}
                style={{
                  width: "3vw",
                  height: "1vh",
                }}
              />
              <Text c={"#2B71B9"} fw={800} style={{ fontSize: 40 }}>
                10+
              </Text>
              <Text size="sm" c={"#2B71B9"} fw={400}>
                Awards Winner{" "}
              </Text>
            </Stack> */}
          </Flex>
        </Flex>

        <Image
          src={"/images/map-stats.png"}
          style={{
            width: "28vw",
          }}
        />
      </Flex>
    </Container>
  );
}

export default CustomersSection;
