import {
  Container,
  Flex,
  Space,
  Stack,
  Text,
  Image,
  Center,
  Anchor,
  BackgroundImage,
  Box,
  Divider,
} from "@mantine/core";
import React from "react";
import classes from "../../../../styles/styles.module.css";
import { ArrowUp } from "@phosphor-icons/react";

function MobileMachineSection() {
  return (
    <Container
      fluid
      p={0}
      style={{
        width: "100%",
      }}
      id="about-us"
    >
      <Flex gap={0} direction={"column"}>
        <Container fluid p={"xl"} bg={"#F21A27"}>
          <Center>
            <Box
              style={{
                width: "100%",
              }}
            >
              <Text
                c={"#FFF"}
                fw={700}
                ta={"center"}
                className={classes["customer-title"]}
                style={{
                  lineHeight: 1.2,
                }}
              >
                Our Drilling Rig machine
              </Text>
              <Divider
                size={6}
                mt={10}
                mx={"auto"}
                color={"#2B71B9"}
                style={{
                  width: "60vw",
                }}
              />
            </Box>
          </Center>
          <Space h={"xl"} />
          <Stack gap={20}>
            <Text c={"#FFF"}>
              GEL owns a Drilling Rig machine that has a capacity to drill a
              600m deep borehole. It has the capability to drill a 450mm
              diameter hole. GEL also owns a mud pump allowing it to perform
              safe mud drilling operations, in areas with extremely loose
              formation.
            </Text>
            <Text c={"#FFF"}>
              The team also employs drill-tracking techniques using mobile
              technology to avail management and the client with live data,
              showing progress throughout the process. GEL also owns a range of
              generators ready for hire. It boasts of power generation from
              40KVA up to 220 KVA, depending on the power requirements. This
              allows GEL to conduct test pumping even with heavy duty pumps.
            </Text>
            <Text c={"#FFF"}>
              The company also owns a state of the art test-pumping unit capable
              of handling a rated weight of 10 tonnes. The machine employs
              technology that allows installation of submersible water pumps as
              deep as 400m. This means GI galvanized pipes can comfortably and
              safely be installed in your well.
            </Text>
          </Stack>
          <Space h={"xl"} />
        </Container>

        <div
          style={{
            position: "relative",
            height: "30rem",
            width: "100%",
          }}
        >
          <Image
            src="/images/drilling-rig.png"
            style={{
              height: "100%",
              width: "100%",
            }}
          />

          <Center
            py={20}
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Anchor
              style={{
                textDecoration: "none",
              }}
              href="#our-services"
            >
              <div
                style={{
                  padding: "10px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F21A27",
                }}
              >
                <ArrowUp size={20} color="#FFF" />
              </div>
            </Anchor>
          </Center>
        </div>
      </Flex>
    </Container>
  );
}

export default MobileMachineSection;
