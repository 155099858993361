import {
    Anchor,
    Center,
    Container,
    Divider,
    Flex,
    Image,
    Space,
    Stack,
    Text,
  } from "@mantine/core";
  import React from "react";
  import classes from "../../../../styles/styles.module.css";
  import { ArrowUp } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
  
  function MobileFooter() {
    const navigate = useNavigate();

    return (
      <Container
        fluid
        px={"xl"}
        py={"xl"}
        style={{
          width: "100%",
          backgroundColor: "#2B71B9",
        }}
        id="main-footer"
      >
        <Flex direction={"column"} justify={"center"} align={"center"}>
          <Image
            src={"/images/white-logo.png"}
            style={{
              width: "30vw",
            }}
          />
          <Space h={50} />
          <Text
            fw={700}
            ta={"center"}
            style={{
              cursor: "pointer",
            }}
            c={"#FFFFFF"}
            onClick={() => {
              navigate("/#about-us")
            }}
            className={classes["service-title"]}
          >
            About Us
          </Text>
          <Space h={"md"} />
          <Stack gap={10}>
            <Text
              c={"#FFFFFF"}
              size="lg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/#company-statement")
              }}
              ta={"center"}
            >
              Mission
            </Text>
            <Divider color="#F21A27" size={"sm"} />
            <Text
              c={"#FFFFFF"}
              size="lg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/#company-statement")
              }}
              ta={"center"}
            >
              Vision
            </Text>
            <Divider color="#F21A27" size={"sm"} />
            <Text
              c={"#FFFFFF"}
              size="lg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/#company-statement")
              }}
              ta={"center"}
            >
              Core values
            </Text>
          </Stack>
          <Space h={50} />
          <Text
            fw={700}
            ta={"center"}
            style={{
              cursor: "pointer",
            }}
            c={"#FFFFFF"}
            onClick={() => {
                navigate("/#about-us")
            }}
            className={classes["service-title"]}
          >
            Location
          </Text>
          <Space h={"md"} />
          <Text c={"#FFFFFF"} size="sm" fw={700} ta={"center"}>
            Address
          </Text>
          <Text c={"#FFFFFF"} size="sm" ta={"center"}>
            P.O Box 818 - 00520, Ruai , Nairobi
          </Text>
          <Space h={50} />
          <Text
            fw={700}
            ta={"center"}
            style={{
              cursor: "pointer",
            }}
            c={"#FFFFFF"}
            className={classes["service-title"]}
          >
            Social Media
          </Text>
          <Space h={"md"} />
          <Flex align={"center"} gap={16} justify={"center"}>
            <Anchor href="#" target="_blank">
              <Image src={"/images/facebook-icon-red.png"} h={22} w={22} />
            </Anchor>
            <Anchor href="#" target="_blank">
              <Image src={"/images/linkedin-icon-red.png"} h={22} w={22} />
            </Anchor>
            <Anchor href="#" target="_blank">
              <Image src={"/images/twitter-icon-red.png"} h={22} w={22} />
            </Anchor>
            <Anchor href="#" target="_blank">
              <Image src={"/images/youtube-icon-red.png"} h={22} w={22} />
            </Anchor>
            <Anchor href="#" target="_blank">
              <Image src={"/images/whatsapp-icon-red.png"} h={22} w={22} />
            </Anchor>
          </Flex>
          <Space h={"md"} />
          <Center py={20}>
            <Anchor
              style={{
                textDecoration: "none",
              }}
              href="#service-details"
            >
              <div
                style={{
                  padding: "10px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F21A27",
                }}
              >
                <ArrowUp size={20} color="#FFF" />
              </div>
            </Anchor>
          </Center>
        </Flex>
      </Container>
    );
  }
  
  export default MobileFooter;
  