import { Anchor, Center, Container, Flex, Text } from "@mantine/core";
import { ArrowCircleDown, ArrowDown } from "@phosphor-icons/react";
import React from "react";

function HomeSection() {
  return (
    <Container
      fluid
      p={64}
      style={{
        height: "100%",
      }}
      id="home"
    >
      <Flex
        direction={"column"}
        justify={"center"}
        style={{
          height: "100%",
          paddingTop: "20vh",
        }}
      >
        <Text
          style={{ color: "#FFF", fontSize: "6.5vw", lineHeight: 0.7 }}
          fw={700}
        >
          Water is
        </Text>
        <Text style={{ color: "#FFF", fontSize: "6.5vw" }} fw={700}>
          Our{" "}
          <Text
            style={{
              textDecorationColor: "#F21A27",
              fontSize: "6.5vw",
              textUnderlineOffset: "30px",
              textDecorationLine: "underline",
            }}
            span
            fw={700}
          >
            Business
          </Text>
        </Text>
      </Flex>
      <Flex justify={"center"} style={{ marginTop: "auto" }}>
        <Anchor
          style={{
            textDecoration: "none",
          }}
          href="#company-statement"
        >
          <div
            style={{
              padding: "10px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F21A27",
            }}
          >
            <ArrowDown size={20} color="#FFF" />
          </div>
        </Anchor>
      </Flex>
    </Container>
  );
}

export default HomeSection;
